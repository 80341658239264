import * as React from "react";
import {Component} from "react";

import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

import "./Loading.scss";

export class Loading extends Component<{message?:string, i18n?:any, size?:"small"|"medium"|"large"}> {
    constructor(props:any) {
        super(props);
    }

    render = () => {
        const { message, i18n, size } = this.props;
        let displayMessage;
        if(i18n) {
            displayMessage = i18n.t(message);
        } else {
            displayMessage = message;
        }

        let sizeClass;
        switch (size) {
            case "small":
                sizeClass = "fa-2x";
                break;
            case "medium":
                sizeClass = "fa-4x";
                break;
            case "large":
                sizeClass = "fa-10x"
                break;
            default:
                sizeClass = "fa-10x"
                break;
        }

        return (
            <div className="d-flex flex-column flex-fill justify-content-center align-items-center">
                <h4>{displayMessage || "Loading..."}</h4>
                <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${sizeClass}`}/>
            </div>
        );
    };
}

export default Loading;