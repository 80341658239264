import * as React from "react";
import {Component} from "react";
import AppController from "../../controllers/AppController";
import {Alert, Col, Container, Jumbotron, Row} from "react-bootstrap";
import Loading from "../loading/Loading";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
// import 'react-circular-progressbar/dist/styles.css';
import moment from "moment";
import "./OtpGenerator.scss";

export class OtpGenerator extends Component<{app:AppController, i18n:any}, {isLoading:boolean, isError:boolean, message:string, otp:string, expires:number, duration:number, seconds:number}> {

    timer:any = null;

    constructor(props:any) {
        super(props);
        this.state = {
            isLoading: false,
            isError: false,
            message: "",
            otp: "",
            expires: 0,
            duration: 0,
            seconds: 0
        }
        console.log("OtpGenerator.tsx: component initialized");
    }

    componentDidMount = async () => {
        console.log("OtpGenerator.tsx: component mounted");
        await this.getGeneratedOTP();
    }

    componentWillUnmount = () => {
        console.log("OtpGenerator.tsx: component will unmount, stopping timer...");
        if(this.timer) {
            clearInterval(this.timer);
        }
    }

    getGeneratedOTP = async () => {
        console.log("OtpGenerator.tsx: retrieving server-side generated OTP...");
        this.stopTimer();
        this.setState({
            isLoading: true
        }, async () => {
            const { app } = this.props;
            const result = await app.rest.getData("otp");
            if(result.status === "ok") {
                const otpObject = result.data;
                const otp = otpObject.otp;
                const expires = otpObject.expires;
                const now = moment();
                const expobj = moment(expires);
                const duration = Math.floor(moment.duration(expobj.diff(now)).asSeconds());
                this.setState({
                    isLoading: false,
                    message: result.message,
                    otp: otp,
                    expires: expires,
                    duration: duration,
                    seconds: duration
                }, this.startTimer);
            } else {
                this.setState({
                    isLoading: false,
                    isError: true,
                    message: result.message,
                    expires: 0,
                    duration: 0,
                    seconds: 0
                })
            }
        });
    }

    startTimer = () => {
        console.log("OtpGenerator.tsx: starting countdown timer...");
        if(this.timer)
            clearInterval(this.timer);
        this.timer = setInterval( () => {
            let { seconds } = this.state;
            seconds--;
            if(seconds <= 0) {
                // get new OTP
                this.getGeneratedOTP();
            } else {
                // decrement counter
                this.setState({
                    seconds: seconds
                });
            }
        }, 1000);
    }

    stopTimer = () => {
        console.log("OtpGenerator.tsx: stopping countdown timer...");
        if(this.timer)
            clearInterval(this.timer);
    }

    render = () => {

        const { app, i18n } = this.props;
        const { isLoading, isError, message, otp, duration, seconds } = this.state;

        if (!app.auth.isLoggedIn()) {
            return (
                <Container className="d-flex flex-column flex-fill justify-content-center align-content-center">
                    <Alert variant="danger">
                        <Alert.Heading><h1>{i18n.t("ERROR")}</h1></Alert.Heading>
                        <p>{`${i18n.t("You must be logged in to access this page")}.`}</p>
                    </Alert>
                </Container>
            )
        } else {
            const ContentBody = () => {
                if(isLoading) {
                    return (
                        <Loading i18n={i18n} message={"Generating OTP"}/>
                    );
                } else if(isError) {
                    return (
                        <Alert variant="danger">
                            <Alert.Heading><h1>{i18n.t("ERROR")}</h1></Alert.Heading>
                            <p>{message}</p>
                        </Alert>
                    );
                } else {
                    const styles = {
                        path: {
                            // stroke: "#BA1100"
                            // stroke: "#6C757D"
                            // stroke: "#f8f9fa"
                            stroke: "#adb5bd"
                        },
                        trail: {
                            // stroke: "#6c757d"
                            stroke: "#BA1100"
                        },
                        background: {
                            fill: "#283044"
                        },
                        "grid-area": "circle"
                    }
                    return (
                        <div className="otp-circle">
                            <CircularProgressbarWithChildren
                                                             value={seconds}
                                                             minValue={0}
                                                             maxValue={duration}
                                                             background={true}
                                                             backgroundPadding={1}
                                                             strokeWidth={11}
                                                             styles={styles}>
                                <h1 className="otp-number">{otp}</h1>
                                <h4 className="otp-remaining">{`${i18n.t("Remaining")}: ${seconds} s`}</h4>
                            </CircularProgressbarWithChildren>
                        </div>
                    );
                }
            }
            /*return (
                <Container className="d-flex flex-column flex-fill justify-content-center align-content-center">
                    <ContentBody />
                </Container>
            );*/
            /*return (
                <Row noGutters={true} className="d-flex flex-row flex-fill justify-content-center align-content-center">
                    <Col className="p-0" sm="2" md="3" lg="4" xl="4" xs="4"/>
                    {/!*<Col className="p-0" sm="8" md="6" lg="4" xl="4" xs="4">*!/}
                    <Col sm="auto">
                        <ContentBody />
                    </Col>
                    <Col className="p-0" sm="2" md="3" lg="4" xl="4" xs="4"/>
                </Row>
            );*/
            return (
                <div className="otp-container">
                    <ContentBody />
                </div>
            )
        }
    }

}

export default OtpGenerator;
